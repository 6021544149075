import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Card } from 'components/common'
import starIcon from 'assets/icons/star.svg'
import forkIcon from 'assets/icons/fork.svg'
import externalLinkIcon from 'assets/icons/external_link.svg'
import { Wrapper, Grid, Item, Content, Stats } from './styles'

export const Projects = () => {
  const {
    github: {
      user: {
        pinnedItems: { edges },
      },
    },
  } = useStaticQuery(
    graphql`
    {
      github {
        user(login: "matt-poloni") {
          pinnedItems(first: 6) {
            edges {
              node {
                ... on GitHub_Repository {
                  id
                  name
                  url
                  description
                  stargazers {
                    totalCount
                  }
                  forkCount
                  homepageUrl
                }
              }
            }
          }
        }
      }
    }
    `
  )
  return (
    <Wrapper as={Container} id="projects">
      <h2>Projects</h2>
      <Grid>
        {edges.map(({ node }) => (
          <Item key={node.id}>
            <Card>
              <Content>
                <h4>
                  <a
                    href={node.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {node.name}
                  </a>
                </h4>
                <p>
                  <ul>
        {node.description.split('. ').map(str => <li>{`${str}`}</li>)
                    }
                  </ul>
                </p>
              </Content>
              <Stats>
                {/* <div>
                  <img src={starIcon} alt="stars" />
                  <span>{node.stargazers.totalCount}</span>
                </div>
                <div>
                  <img src={forkIcon} alt="forks" />
                  <span>{node.forkCount}</span>
                </div> */}
                {!!node.homepageUrl ? (<div>
                  <a
                    href={node.homepageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={externalLinkIcon} alt="external link" />
                    <span>{'Live Project'}</span>
                  </a>
                </div>) : ''}
              </Stats>
            </Card>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  )
}
