import styled from 'styled-components';
import overlayIllustration from 'assets/illustrations/overlay.svg';

export const Wrapper = styled.div`
  // padding-bottom: 4rem;
  // background-image: url(${overlayIllustration});
  // background-size: contain;
  // background-position: right top;
  // background-repeat: no-repeat;
`;

export const IntroWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 680px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 2;
  margin-left: 2rem;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  @media (max-width: 680px) {
    margin-left: unset;
  }

  h1 {
    margin-bottom: 0.5rem;
    font-size: 32pt;
    color: #212121;

    @media (max-width: 680px) {
      font-size: 26pt;
    }
  }

  h4 {
    // margin-bottom: 2rem;
    font-size: 28pt;
    font-weight: normal;
    color: #707070;

    @media (max-width: 680px) {
      font-size: 22pt;
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;
  max-width: 270px;
  
  @media (max-width: 960px) {
    width: 100%;
  }
  
  img {
    border-radius: 10% 10% 10% 10%;
    width: 100%;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 0 1rem;

    img {
      width: 2rem;
      margin: 0;
    }

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
`;
