import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container, Button } from 'components/common'
import developer_activity from 'assets/illustrations/developer_activity.svg'
import { Wrapper, AboutWrapper, Details, Thumbnail } from './styles'
import config from '../../../data/config'

const email = `matt.poloni%2Bportfolio@gmail.com`

export const About = () => (
  <Wrapper id="about">
    <AboutWrapper as={Container}>
      <Details>
        <h1 id="contact">About Me</h1>
        <p>
          I'm a full stack JavaScript web developer with a proven track record of teamwork as both a teammate and team lead. I prefer to build sturdy projects on top of known quantities, but with an eye towards flexibility for whatever may come next.
        </p>
        <Button 
          href={`mailto:${email}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact me
        </Button>
      </Details>
      <Thumbnail>
        <img src={developer_activity} alt={config.defaultDescription} />
      </Thumbnail>
    </AboutWrapper>
  </Wrapper>
)
