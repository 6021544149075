import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container, Button } from 'components/common'
import skills from 'assets/illustrations/skills.svg'
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles'
import config from '../../../data/config'

const email = "matt.poloni+portfolio@gmail.com"

export const Skills = () => (
  <Wrapper id="skills">
    <SkillsWrapper as={Container}>
      <Thumbnail>
        <img src={skills} alt={config.defaultDescription} />
      </Thumbnail>
      <Details>
        <h1>Skills</h1>
        <p>
          I'm fluent in ES6+, React, Redux, Node, Express, Knex, and PostgreSQL with some experience building Django and Flask backends in Python 3. I can also whip up websites in semantic HTML with styling via any of CSS, LESS, or SCSS.
        </p>
      </Details>
    </SkillsWrapper>
  </Wrapper>
)
