import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Header } from 'components/theme'
import { Container, Button } from 'components/common'
import mudcats from 'assets/images/mudcats.jpg'
import { Wrapper, IntroWrapper, Details, Thumbnail, Links } from './styles'
import config from '../../../data/config'
import social from '../../../data/social.json'

export const Intro = () => (
	<Wrapper>
		<Header />
		<IntroWrapper as={Container}>
			<Thumbnail>
				<img src={'https://avatars1.githubusercontent.com/u/46501814'} alt={config.defaultDescription} />
			</Thumbnail>
			<Details>
				<h1>{config.defaultTitle}</h1>
				<h4>
          {config.defaultDescription}
        </h4>
        <Links>
          {social.map(({ id, name, link, icon }) => (
            <a
              key={id}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`follow me on ${name}`}
            >
              <img src={icon} alt={name} />
            </a>
          ))}
        </Links>
				{/* <Button as={AnchorLink} href="#contact">
					Hire me
				</Button> */}
			</Details>
		</IntroWrapper>
	</Wrapper>
)
